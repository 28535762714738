import React from "react";

import Slider from "react-slick";

const Testimonials = ({ classicHeader, darkTheme }) => {
  const reviews = [
    {
      name: "Muhammad Abubakar Dar",
      position: "Senior Software Architect",
      desc: "Capable and responsible professional. I found him a very good team player with ability to resolve conflicts positively. Open to accept challenges and can complete them with minimum supervision. He always strives to an excellent solution, feel no hesitation in asking for suggestions to improve. Quick learner and he has good understanding about the Microsoft technology stack as well as system design. I really appreciate his contribution in the project.",
      rating: 5,
    },
    {
      name: "Zeshan Munir",
      position: "Software Architect",
      desc: "Umer and I worked together at Systems Limited, on an enterprise level project by the provincial government. He joined the team as a fresh graduate, he had a great level of potential in him. He paved his way up the ladder with his hard work and his strive for perfection. He has exceptionally good technical skills, and pays great attention to the requirement details and then strives hard for timely and quality delivery. He is a great human being as well. I would love to work with him in future, and I highly recommend him for any position that requires a great developer with exceptional technical skills.",
      rating: 5,
    },
    {
      name: "Zahid Mughal",
      position: "Web Engineer",
      desc: "I have recently worked with Umer Waheed(Software Engineer) at systems limited, where I find him, he have exceptional technical, problem solving and debugging skills as well as have capability to comprehend complex domain knowledge very well. Always curious about his tasks and delivered them in time. Umer Waheed is a very humble personality. He has great relations with both company and colleagues. Highly recommended!",
      rating: 5,
    },
    {
      name: "Muhammad Tayyab Razzaq",
      position: "Full Stack Web Developer",
      desc: "It was a pleasure of working with Umer at Systems Limited. During this time, we worked together on a project related to Land Management System. Umer is a person with a strong ownership and driving for results all the time. He sees and can address everything from the big picture to the details. Umer is creative, energetic, solutions oriented and highly motivated with great communication skills. He is an asset to any company that he’s with. I wish him a great future ahead and I strongly recommend him for a position in any reputable organization.",
      rating: 5,
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <section
      id="testimonial"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Testimonial
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Reviews
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}

        <Slider {...settings}>
          {reviews.length > 0 &&
            reviews.map((value, index) => (
              <div className="item" key={index}>
                <div
                  className={
                    " rounded p-5 " + (darkTheme ? "bg-dark" : "bg-light")
                  }
                >
                  <div className="d-flex align-items-center mt-auto mb-4">
                    {/* <img
                      className="img-fluid rounded-circle border d-inline-block w-auto"
                      src={value.src}
                      alt=""
                    /> */}
                    <p className="ms-3 mb-0">
                      <strong
                        className={
                          "d-block fw-600 " +
                          (darkTheme ? "text-white" : "text-dark")
                        }
                      >
                        {value.name}
                      </strong>
                      <span className="text-muted fw-500">
                        {" "}
                        {value.position}{" "}
                      </span>
                    </p>
                  </div>
                  <p
                    className={
                      " fw-500 mb-4 " + (darkTheme ? "text-white" : "text-dark")
                    }
                  >
                    “{value.desc}”
                  </p>
                  <span className="text-2">
                    {[...Array(value.rating)].map((value, i) => (
                      <i className="fas fa-star text-warning" key={i} />
                    ))}
                  </span>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
