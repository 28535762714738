import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
    DESIGN: "Projects",
    BRAND: "Certifications"
  };

  const projectsData = [
    {
      title: "Caritag",
      client: "ETO",
      projectInfo:
        "The Caritag project was a large-scale data management project that aimed to handle a large volume of messages coming from a device called caritag which people wear on their hand.",
      technologies: "Microservices, Azure Services, Eventhub, IOT Hub",
      date: "Dec, 2021",
      
      industry: "Location Finder",
      thumbImage: "images/projects/caritag-1.jpg",
      sliderImages: [
        "images/projects/caritag-2.jpg",
      ],
      categories: [filters.DESIGN],
    },
    {
      title: "Nambaya",
      client: "Nambaya",
      projectInfo:
        "NAMBAYA is a digital health company specialising in products broadly called telemedicine. They use technology to measure heart and general health, including identifying risks like lack of sleep and high stress. NAMBAYA has been designed for easy and unobtrusive daily wear using a single sensor clipped into a self-adhesive two-lead electrode. ",
      technologies: "Microservices, Rabit MQ, Docker, C#, DOTNET CORE",
      date: "Nov, 2021",
      
      industry: "Health",
      thumbImage: "images/projects/nambaya-1.jpg",
      sliderImages: [
        "images/projects/nambaya-2.jpg"
      ],
      categories: [filters.DESIGN],
    },
    {
      title: "Tower-Cluster Controller",
      client: "ASM",
      projectInfo:
        "Tower-Cluster Controller is a desktop application for Dutch company active in semiconductor industry. TCC is responsible to manage inventory with storage unit in the production factory. It communicates with storage units called towers with the help of software proxy MTSI (Material Tower Station Interface) developed by ASM. TCC provides a way to synchronize inventory with storage unit. Create picklist to discharge material units from storage unit. Also, TCC is responsible to control AIV (Automated Intelligent Vehicle) with communicating with Fleet Manager. AIV is used to transfer (Load/Unload) stock items cassette in material tower.",
      technologies: "C#, WCF, WPF, SQL, WEB API, MSMQ",
      date: "May, 2019",
      
      industry: "Semiconductor",
      thumbImage: "images/projects/ASM.jpeg",
      sliderImages: [
        "images/projects/TCC-1.jpg",
        "images/projects/TCC-2.jpg",
      ],
      categories: [filters.DESIGN],
    },
    {
      title: "Advanced Performance Monitor",
      client: "ASM",
      projectInfo:
        "It is a performance monitor application for Dutch company active in the semiconductor industry. This is a project to monitor the production of different boards based on how much components are being used and wasted with scheduled and unsaddled downtime. There are different Key Performance Indicator (KPIs) that reflects how well an organization is achieving its stated goals and objectives.",
      technologies: "C#, Angular, Web Sockets, SQL, WCF",
      date: "October, 2017 - Feb, 2019",
      industry: "Semiconductor",
      thumbImage: "images/projects/apm-1.jpg",
      sliderImages: [
        "images/projects/apm-1.jpg"
      ],
      categories: [filters.DESIGN],
    },
    {
      title: "Land Record Managment Information System",
      client: "Punjab Goverment",
      projectInfo:
        "Land Records Management Information System Punjab is set to be one of the largest Land Records’ databases in South East Asia. This is a mega project to develop a new centralized Land Records Management Information System (LRMIS). The new system will replace the current distributed system and will ensure scalability and uniform service and functionality across the 300 services centers in Punjab.",
      technologies: "C#, ASP.NET MVC, SQL, JQuery, JQGRID",
      date: "January 28, 2022",
      industry: "Land Record",
      thumbImage: "images/projects/LRMIS-1.png",
      sliderImages: [
        "images/projects/LRMIS-2.jpg",
      ],
      categories: [filters.DESIGN],
    },
    {
      title: "Microsoft Certified: Azure Fundamentals",
      client: "N/A",
      projectInfo:
        "Earners of the Azure Fundamentals certification have demonstrated foundational level knowledge of cloud services and how those services are provided with Microsoft Azure.",
      technologies: "Azure, Cloud Data, Cloud Networking, Cloud Security, Cloud Storage",
      date: "January 28, 2022",
      url: {
        name: "https://www.credly.com/badges/3f3cde05-7c60-42b1-bdee-e66a0ab4b20b/linked_in_profile",
        link: "https://www.credly.com/badges/3f3cde05-7c60-42b1-bdee-e66a0ab4b20b/linked_in_profile",
      },
      industry: "Azure Cloud",
      thumbImage: "images/projects/C2.jpg",
      categories: [filters.BRAND],
    },
    {
      title: "Microsoft Certified: Azure Developer Associate",
      client: "N/A",
      projectInfo:
        "Earning Azure Developer Associate certification validates the skills and knowledge to design, build, test, and maintain cloud applications and services on Microsoft Azure. Candidates participate in all phases of cloud development from requirements definition and design, to development, deployment, and maintenance.",
      technologies: "Azure API Management, Azure App Security, Event Hub, Azure IAAS, Azure PAAS, Azure Containers",
      industry: "Azure Cloud",
      date: "February 17, 2021",
      url: {
        name: "https://www.credly.com/badges/af9d5cce-0d4e-48e8-bfd7-57a8008bef0e/linked_in_profile",
        link: "https://www.credly.com/badges/af9d5cce-0d4e-48e8-bfd7-57a8008bef0e/linked_in_profile",
      },
      thumbImage: "images/projects/C3.jpg",

      categories: [filters.BRAND],
    },
    {
      title: "DRUID AI",
      client: "N/A",
      industry: "Chatbot",
      projectInfo:
        "DRUID AI-powered virtual assistants enable fast, personalized, and hyper-automated interactions designed to deliver next-level employee productivity and a superior customer experience. Our conversational AI solutions speak each organization's language through seamless integrations with any enterprise system and Robotic Process Automation (RPA) technologies. ",
      technologies: "UIPath, ChatBot",
      date: "January 01, 2022",
      url: {
        name: "https://www.druidai.com/",
        link: "https://www.druidai.com/",
      },
      thumbImage: "images/projects/C1.jpg",
      categories: [filters.BRAND],
    }
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
